<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-02 17:11:51
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-15 14:02:16
-->
<template>
    <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px;" :width="1000" v-model="visible" @ok="toSubmit" okText="提交" :maskClosable="false">
        <a-form-model ref="form" :model="rowData" :rules="formRule" layout="horizontal" :label-col="{span:3}" :wrapper-col="{span:21}">
            <a-form-model-item label="所属岗位" prop="positionId" :autoLink="false" ref="test">
                <a-select placeholder="请选择所属岗位" v-model="rowData.positionId" @change="onChange">
                    <a-select-option :value="item.id" v-for="(item, index) in positionList" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="名称" prop="name">
                <a-input v-model="rowData.name" placeholder="名称"></a-input>
            </a-form-model-item>
            <a-form-model-item label="是否启用" prop="flagEnable">
                <a-switch v-model="rowData.flagEnable" checked-children="启用" un-checked-children="禁用" default-checked />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
                <a-input v-model="rowData.remark" placeholder="备注"></a-input>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import { editDealerPosition, selectByIdDealerPosition, addDealerPosition } from '../api/DealerPositionApi'
export default {
    data() {
        return {
            visible: false,
            handle: 'add',
            rowData: {},
            // 表单验证
            formRule: {
                positionId: [
                    { required: true, message: '请输入所属岗位', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' }
                ],
                flagEnable: [
                    { required: true, message: '请输入是否启用', trigger: 'blur' }
                ],
                remark: [
                    { required: false, message: '请输入备注', trigger: 'blur' }
                ],
            },
            positionList: []
        }
    },
    methods: {
        /**
         * 获取行数据
         */
        setRowData(row, handle){
            this.handle = handle
            this.visible = true
            this.rowData = {}
            this.getStationList()
            if(handle == 'add') {
                this.$set(this.rowData, 'flagEnable', true)
            }
            if(handle == 'edit') {
                selectByIdDealerPosition(row.id).then(res => {
                    this.rowData = res.body
                })
            }
        },

        getStationList() {
            this.axios.get('/api/base/system/station/listDealerAll').then(res => {
                this.positionList = res.body
            })
        },
        onChange() {
            this.$refs.test.onFieldChange()
        },
        /**
         * 表单提交
         */
        toSubmit() {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    return
                }
                const res = this.handle === 'add' ? await addDealerPosition(this.rowData) : await editDealerPosition(this.rowData)
                if (res.code === 200) {
                    this.$notification.success({ message: res.message })
                    this.$emit('reload')
                    this.visible = false
                    this.rowData = {}
                } else {
                    this.$notification.error({ message: res.message })
                }
            })
        }
    },
    created() {

    },

}
</script>

<style lang="less" scoped>

</style>
